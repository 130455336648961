import {
  getAuthStatus,
  getCustomer,
  getPets,
  getSubscription,
} from '@front/common/endpoints'
import { StatusType } from '@front/common/types'
import {
  getActiveServices,
  getCustomFoodFromServices,
  getServicesByPetId,
  isObjectEmpty,
  serviceHasWetFood,
} from '@front/common/utils'

import {
  SHOWCASE_ROUTE_DELIVERY,
  SHOWCASE_ROUTE_DISCOVERY_OFFER,
  SHOWCASE_ROUTE_MENU,
  SHOWCASE_ROUTE_PRODUCTS,
} from '@showcase/core/routes-names'
import { KIBBLE_DIET, MIXED_DIET } from '@showcase/helpers/constants'

// @TODO: Migrate middleware for vue-router navigation guards avoiding any nuxt relation
export default async function ({ route, store, redirect }) {
  if (!route.query?.referer) {
    if (
      [
        SHOWCASE_ROUTE_DELIVERY,
        SHOWCASE_ROUTE_PRODUCTS,
        SHOWCASE_ROUTE_DISCOVERY_OFFER,
      ].includes(route.name) &&
      isObjectEmpty(store.getters['cart/getSubscription'])
    ) {
      redirect({ name: SHOWCASE_ROUTE_MENU })
      return
    }

    return
  }

  try {
    const { id, status } = await getAuthStatus()

    if (status === StatusType.unauthenticated) {
      redirect({ name: SHOWCASE_ROUTE_MENU })
      return
    }

    const [pets, subscription, customer] = await Promise.all([
      getPets(id),
      getSubscription(id),
      getCustomer(id),
    ])

    store.commit('customer/update', customer)
    store.commit(
      'profileBuilder/setVisitedStepsName',
      pets.map(() => {
        return store.getters['profileBuilder/getDisplayedSteps'].map(
          ({ name }) => name,
        )
      }),
    )

    const petsWithDiet = pets.map((pet) => {
      const diet = getServicesByPetId(subscription.services, pet.id).some(
        serviceHasWetFood,
      )
        ? MIXED_DIET
        : KIBBLE_DIET

      return { ...pet, diet }
    })

    store.commit('pets/setDrafts', petsWithDiet)
    store.dispatch('pets/validateDrafts')
    store.commit('profileBuilder/setCompleted')

    await store.dispatch('cart/upsert', subscription)
    await store.dispatch(
      'renewalServices/fetch',
      getActiveServices(getCustomFoodFromServices(subscription?.services)),
    )

    return
  } catch {
    redirect({ name: SHOWCASE_ROUTE_MENU })
  }
}
