import { fetchTranslations, translate } from './translation'

export default {
  install(Vue, { noClientMode = false, withWarning = true, router } = {}) {
    router.beforeEach(async (to, from, next) => {
      // Used when new applications does not have prismic settings yet.
      const pages =
        typeof to.meta.i18nPages === 'function'
          ? to.meta.i18nPages(to)
          : to.meta.i18nPages || []

      if (!noClientMode) {
        await fetchTranslations(pages || [])
      }

      if (Vue.prototype) {
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$i18n = (args, variables) =>
          translate(args, variables, withWarning)
      }

      next()
    })
  },
}
