import { computed } from 'vue-demi'

import Vuex from '@front/common/vuex'

export default () => {
  const store = Vuex.useStore()

  const isOpen = computed(() => {
    return store.getters['alert/getVisibility']
  })

  const status = computed(() => {
    return store.getters['alert/getStatus']
  })

  const message = computed(() => {
    return store.getters['alert/getMessage']
  })

  const delay = computed(() => {
    return store.getters['alert/getDelay']
  })

  function handleOpenAlert(options) {
    store.dispatch('alert/open', options)
  }
  function closeAlert() {
    store.dispatch('alert/close')
  }

  function HOCtriggerAlert(fn, options) {
    return async (...args) => {
      await fn(...args)

      handleOpenAlert(options)
    }
  }

  return {
    delay,
    message,
    status,
    isOpen,
    triggerAlert: (options) => handleOpenAlert(options),
    HOCtriggerAlert,
    closeAlert,
  }
}
