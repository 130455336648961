import { InferType, array, boolean, number, object, string } from 'yup'

export enum NamespaceType {
  public = 'public',
  private = 'private',
}

export type namespaceConstantType = [string, string]

export enum StatusType {
  unauthenticated = 'unauthenticated',
  authenticated = 'authenticated',
}

export enum RoleType {
  japhytos = 'japhytos',
  customer = 'customer',
}

export interface Role {
  createdAt: string
  description: string
  id: number
  name: RoleType
  policies: any[]
  updatedAt: string
}

export enum RecipeFlavor {
  fish = 'fish',
  beef = 'beef',
  chicken = 'chicken',
}

export enum GrowthStage {
  baby = 'baby',
  adult = 'adult',
  senior = 'senior',
}

export enum ItemType {
  standardisedKibble = 'standardised_kibble_recipe',
  kibble = 'kibble_recipe',
  wetfood = 'wetFood_recipe',
  upsell = 'upsell',
  gift = 'gift',
  goodie = 'goodie',
}

export enum ServiceType {
  upsell = 'upsell',
  customFood = 'custom_food',
  goodie = 'goodie',
}

export enum DiscountType {
  percentage = 'percentage',
  fixedAmount = 'fixedAmount',
}

export enum PricingType {
  base = 'base',
  baseWithPromotion = 'baseWithPromotion',
  trialKilogram = 'trialKilogram',
  trial = 'trial',
  monthly = 'monthly',
}

export type Pricings = Partial<{
  [key in PricingType]: {
    amount: number
    currency: string
  }
}>

export interface PromoCode {
  code: string
  countdown: number
  creationDate: string
  description: string
  effects: any
  endAt: string
  id: number
  imageUrl: string
  initialCountdown: number
  promoType: string
  startAt: string
  type: string
}

export const petSchema = object({
  birth: object().defined(),
  id: number().required().integer(),
  breeds: array().min(1).of(object().defined()).defined(),
  resourceId: string(),
  averageAdultWeight: number().integer(),
  birthdayDay: string(),
  wetFoodRatio: string().optional(),
  flags: object().optional(),
  name: string().required(),
  diet: string().required(),
  species: string().required(),
  gender: string().required(),
  growthStage: string().nullable(),
  isSpayed: boolean().defined(),
  weight: number().required().positive().integer(),
  serviceIds: array().of(string()),
  weightSources: object(),
  activityLevel: number().required().positive().integer(),
  corpulenceLevel: number().required().positive().integer(),
  pathologies: array(),
  status: string(),
})

export type Pet = InferType<typeof petSchema>

export interface Rule {
  id: number
  resourceId?: number
  weight: number
  articles: any
  isActive: string
  createdAt: string
  updatedAt: string
}

export interface Recipe {
  id: number
  article: any
  articleId: number
  ingredients: string[]
  label: string
  marketLabel: string
  metaboEnergy: number
  reference: string
  species: string
  diameter: number
  rules: Rule[]
  templateFile: string
  imageUrls: any
}

export interface Item {
  id: number
  promoCodeId?: number
  type: ItemType
  article: any
  recipe: any
  productId: number
  quantity: any
  pricing: Pricings
  imageUrls: any
  isNew: boolean
  translationKeys: any
  deliveryFrequency?: number
  serviceId?: number
  petId?: number
}

export interface Service {
  id: number
  petId: number
  deliveryFrequency?: number
  subscriptionId: number
  type: ServiceType
  nextBillingDate?: string
  expectedDeliveryDateRange?: [Date, Date]
  catchphrase: string
  status: string
  pricing: Pricings
  items: Item[]
  createdAt?: string
  updatedAt?: string
}

export interface Prospect {
  firstName: string
  lastName: string
  email: string
  address: string
  additionalAddress: string
  city: string
  company: string
  postalCode: string
  country: string
  phoneNumber: string
  pointRelayAddress: any
  promoCode: PromoCode
  registrationCode: PromoCode
}

export interface Customer {
  id: number
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
  billingContact: any
  deliveryContact: any
  discount: any
  nextBillingDate: string
  nextDeliveryDate: string
  nextDebitDate: string
  registrationCode: PromoCode
  sponsorshipCode: PromoCode
  shippingFees: any
  shippingFeesWithoutPromotion: any
  subscriptionStatus: string
  statistics: any
  registered: false
  registrationDate: string
  unsubscribeDateAble: string
  flags: any
  totalMonthlyPrice: any
  totalSubscriptionPrice: any
  totalSubscriptionPriceWithoutPromotion: any
  totalMonthlyPriceWithoutPromotion: any
}

export interface LightDiscount {
  freeShipping: boolean
  kibblePercent: number
}

export interface Subscription {
  id: number
  customerId: number
  paymentMethodId: number
  deliveryFrequency: number
  recommendedDeliveryFrequency: number
  nextDeliveryDate: string
  nextBillingDate: string
  lastShippingDate: string
  shippingFees: any
  nextBillingOffset: number
  nextDebitDate: string
  isTrial: string
  isUnpaid: string
  pricingVersion: number
  multiPetDiscountVersion?: number
  createdAt: string
  updatedAt: string
  services: Service[]
  discount: LightDiscount
  shippingFeesType: string
  pricing: Pricings
}

export type DiscountReason =
  | 'godfather'
  | 'godson'
  | 'giftCard'
  | 'resubscribe'
  | 'acquisition_offer'
  | 'agentPartialReimbursement'
  | 'agentCommercialGesture'
  | ''

export interface Discount {
  id: number
  customerId: number
  type: DiscountType
  amount: number
  initialCountdown: number
  countdown: number
  isCumulative: string
  target: string
  conditions: any[]
  reason: DiscountReason
  createdAt: string
  updatedAt: string
  isDeleted?: 'yes' | 'no'
}

export interface Order {
  id: number
  customerId: number
  pets: null
  type: string
  status: string
  carrierPreference: string
  billingContact: any
  deliveryContact: any
  products: any[]
  priority: number
  note: string
  packages: any
  duration: number
  expectedShippingDate: string
  shippingDate: any
  expectedDeliveryDate: string
  createdAt: string
  updatedAt: string
  transaction: any
}

export interface NextOrder {
  id: number
  customerId: number
  petIds: number[]
  serviceIds: number[]
  email: string
  carrierPreference: string
  type: string
  externalId: any
  partnerId: number
  status: string
  billingContact: any
  deliveryContact: any
  deliveryFee: number
  priority: number
  note: string
  expectedShippingDate: Date
  expectedDeliveryDateRange: [Date, Date]
  shippingDate: any
  weight: number
  TransactionId: number
  createdAt: Date
  updatedAt: Date
  items: any[]
  packages: any[]
  synchronizedAt: Date
  pets: any[]
  products: any[]
  expectedDeliveryDate: Date
  transaction: any
}

export interface PickupPoint {
  id: number
  externalId: string
  address: string
  additionalAddress: string
  thirdAddress: string
  city: string
  postalCode: string
  countryCode: string
  name: string
  distance: number
  coord: { lat: number; lng: number }
  openingHours: {
    monday: string
    tuesday: string
    wednesday: string
    thursday: string
    friday: string
    saturday: string
    sunday: string
  }
  mobilityEnabled: false
  type: string
  carrier: string
  maxWeight: number
}

export type DiscountCollection = {
  multiPetDiscounts: Discount[]
  postRegistration: Discount[]
  registration: Discount
}

export enum FlowType {
  classic = 'classic-mode',
  creation = 'creation-mode',
  update = 'updating-mode',
}

export enum PetCountFlowType {
  single = 'single',
  two = 'two',
  infinite = 'infinite',
}

export interface PetItems {
  [petId: number]: Item
}

export interface Category {
  id: number
  type: string
  subtype: string
  label: string
  visibleToCustomer: boolean
  rank: number
}

export interface Packing {
  ArticleID: number
  PackArticleID: number
  Size: number
}

export interface Article {
  packings: Packing[]
  id: number
  label: string
  isSaleable: boolean
  pcb: number
  saleUnit: string
  supplier: number
  type: string
  weightUnit: string
  weight: number
  createdAt: Date
  updatedAt: Date
}

export interface Gift {
  article: Article
  countdown: number
  customerID: number
  id: number
  initialCountDown: number
  quantity: number
  reason: string
  createdAt: Date
  updatedAt: Date
}

export interface PaymentMethod {
  id: number
  customerId: number
  provider: string
  providerUserId: string
  providerPaymentMethodId: string
  expiration: string
  lastNumber: string
  createdAt: Date
  updatedAt: Date
}

export interface Transaction {
  id: number
  orderId: number
  subscriptionId: number
  customerId: number
  context: string
  externalId: string
  paymentProvider: string
  status: string
  price: {
    amount: number
    currencyCode: string
    vat: number
  }
  attemptCount: number
  nextAttemptAt: Date | null
  details: {
    discountAmount: {
      amount: number
      currencyCode: string
      vat: number
    }
    shippingFees: {
      amount: number
      currencyCode: string
      vat: number
    }
    orderId: number
    serviceIds: number[]
  }
  createdAt: Date
  updatedAt: Date
  contact: any
}

export interface Note {
  id: number
  customerID: number
  comment: string
}

export interface Call {
  createdAt: Date
  updatedAt: Date
  string
  customerId: number
  id: number
  note: string
  result: string
  reasons: any[]
}

export interface Reason {
  id: number
  name: string
  label: string
  translationKey: string
  type: string
}

export interface SubscriptionDiscount {
  activeMultiPetDiscount: number
  multiPetDiscounts: any[]
}
