var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.$style.wrapper,
    ( _obj = {}, _obj[_vm.$style.success] = _vm.status === _vm.SUCCESS, _obj[_vm.$style.failure] = _vm.status === _vm.FAILURE, _obj ) ]},[_c('div',{class:_vm.$style.iconWrapper},[(_vm.status === _vm.SUCCESS)?_c('img',{class:_vm.$style.statusIcon,attrs:{"src":require("./icons/alert-success.svg")}}):_vm._e(),(_vm.status === _vm.FAILURE)?_c('img',{class:_vm.$style.statusIcon,attrs:{"src":require("./icons/alert-error.svg")}}):_vm._e()]),_c('div',{class:_vm.$style.content},[(_vm.$slots.title)?_c('Typography',{class:_vm.$style.title,attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodyBold}},[_vm._t("title")],2):_vm._e(),(_vm.$slots.default)?_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodySmallRegular}},[_vm._t("default")],2):_vm._e()],1),(_vm.delay)?_c('Button',{class:_vm.$style.button,attrs:{"appearance":"clean"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('Icon',{class:_vm.$style.closeIcon,attrs:{"name":"xmark"}})],1):_vm._e(),_c('div',{class:_vm.$style.progressBar,style:({
      background: _vm.progressionStatusColors,
      width: (_vm.barProgression + "%"),
    })})],1)}
var staticRenderFns = []

export { render, staticRenderFns }