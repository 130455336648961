function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{ref:"options",class:_vm.$style.options,attrs:{"id":"results","tabindex":"0","role":"listbox","aria-activedescendant":_vm.getUniqueId(_vm.focusedItemIndex)},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.handleUpKey.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.handleDownKey.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleKeyboardSelect.apply(null, arguments)}]}},[(_vm.isResultEmpty)?_c('Typography',{class:_vm.$style.option,attrs:{"tag":"li"}},[_vm._v("\n    "+_vm._s(_vm.$i18n('Aucun résultat'))+"\n  ")]):_vm._e(),_vm._l((_vm.list),function(ref,index){
var _obj, _obj$1, _obj$2;

var value = ref.value;
var label = ref.label;
var disabled = ref.disabled;
var rest$1 = objectWithoutProperties( ref, ["value", "label", "disabled"] );
var rest = rest$1;return _c('li',{key:_vm.getUniqueId(index),class:[
      _vm.$style.option,
      ( _obj = {}, _obj[_vm.$style.isActive] = _vm.isItemActive(value), _obj ),
      ( _obj$1 = {}, _obj$1[_vm.$style.isFocused] = _vm.isItemFocused(index), _obj$1 ),
      ( _obj$2 = {}, _obj$2[_vm.$style.disabled] = disabled, _obj$2 ) ],attrs:{"id":_vm.getUniqueId(index),"role":"option","aria-selected":_vm.isItemFocused(index)},on:{"click":function($event){$event.stopPropagation();return _vm.handleSelected(value)}}},[_c('Typography',[_vm._t("default",function(){return [_vm._v("\n        "+_vm._s(label)+"\n      ")]},{"label":label},rest)],2)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }