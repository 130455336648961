import i18n from '@front/common/i18n/plugin.vue2'
import { fetchTranslations, translate } from '@front/common/i18n/translation'
import Vue from 'vue'

export default async ({ route, app }) => {
  if (process.client) {
    Vue.use(i18n, {
      router: app.router,
      withWarning: process.env.APP_ENV !== 'production',
    })
    return
  }

  await fetchTranslations(route.meta[0]?.i18nPages || [])

  if (Vue.prototype) {
    Vue.prototype.$i18n = translate
  }
}
