
import { loadFonts, fontsToLinks } from './utils/fontLoader'

import './font-face.css';

const fonts = [{"preload":true,"source":{"path":"/fonts/Geomanist-Regular.woff2","format":"woff2"},"classes":["font_geomanist","font_geomanist_200_normal"]},{"preload":true,"source":{"path":"/fonts/Geomanist-Medium.woff2","format":"woff2"},"classes":["font_geomanist","font_geomanist_400_normal"]},{"preload":true,"source":{"path":"/fonts/Geomanist-Bold.woff2","format":"woff2"},"classes":["font_geomanist","font_geomanist_700_normal"]},{"preload":true,"source":{"path":"/fonts/Geomanist-Black.woff2","format":"woff2"},"classes":["font_geomanist","font_geomanist_900_bold"]},{"preload":false,"source":{"path":"/fonts/Hanoded.woff2","format":"woff2"},"classes":["font_hanoded","font_hanoded_normal_normal"]}];

if (!process.server){
  loadFonts({"ignoredEffectiveTypes":["2g","slow-2g"],"ignoreLighthouse":true,"unlockDelay":0,"prefetchCount":2});
}

export default function ({ app }) {
  app.head.link.push(...fontsToLinks(fonts));
}
